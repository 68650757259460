// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-blog-author-tsx": () => import("./../../../src/layouts/blog/Author.tsx" /* webpackChunkName: "component---src-layouts-blog-author-tsx" */),
  "component---src-layouts-blog-blog-post-tsx": () => import("./../../../src/layouts/blog/BlogPost.tsx" /* webpackChunkName: "component---src-layouts-blog-blog-post-tsx" */),
  "component---src-layouts-blog-blog-tsx": () => import("./../../../src/layouts/blog/Blog.tsx" /* webpackChunkName: "component---src-layouts-blog-blog-tsx" */),
  "component---src-layouts-blog-category-tsx": () => import("./../../../src/layouts/blog/Category.tsx" /* webpackChunkName: "component---src-layouts-blog-category-tsx" */)
}

