export const ExternalSwitches = {
  WEB_ATTRIBUTION_SURVEY: "web_attribution_survey_v2",
  WEB_EXTERNAL_PARENTS_FIRST: "web_external_parents_first_v4",
  WEB_EXTERNAL_ACTIVITY_CORNER_POPUP_SIGNUP: "web_external_activity_corner_popup_signup",
  WEB_EXTERNAL_LOGIN_TO_HOME: "redirect_parent_login_home_v2",
  WEB_EXTERNAL_REDIRECT_SL_ON_LOGIN: "web_external_redirect_sl_on_login",
  WEB_EXTERNAL_HOMEPAGE_EXPERIMENT_2024: "web_external_homepage_experiment_2024_v2",
  WEB_EXTERNAL_SCHOOL_STAFF_SELECTOR: "web_external_school_staff_selector",
  WEB_EXTERNAL_PASSWORD_ERROR: "web_external_password_error",
} as const;

export const LiteSwitches = {
  WEB_LITE_REDIRECT_EMAIL_VERIFICATION: "web_lite_redirect_email_verification_v2",
};
export const TeacherSwitches = {} as const;

export const featureSwitches = Object.values({ ...ExternalSwitches, ...TeacherSwitches, ...LiteSwitches });

export const defaultFSValues = featureSwitches.reduce((acc, fs) => ({ ...acc, [fs]: "off" }), {});
